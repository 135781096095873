import AbstractPlayerController from "./abstract_player_controller";

export default class AudioPlayerController extends AbstractPlayerController {
  static targets = [
    "fixedaudioplayer",
    "leadaudioplayer",
    "audiotag",
    "playbutton",
    "expandedPlaybutton",
    "playicon",
    "expandedPlayicon",
    "pauseicon",
    "expandedPauseicon",
    "closeButton",
    "controllers",
    "controllerprogressstring",
    "controllerdurationstring",
    "progressbar",
    "progressbarseek",
    "progressbarseektooltip",
    "compactProgressbarContainer",
    "compactProgressbar",
    "headline",
    "playerContainer",
    "compactView",
    "expandedView",
    "expandedHeadline",
    "compactCoverImage",
    "expandedCoverImage",
    "queueList",
    "compactVignette",
    "expandedVignette",
    "queueItemTemplate",
  ];

  static values = {
    isMain: Boolean,
    audioQueue: {
      type: Array,
      default: [],
    },
    audioUrl: String,
    audioHeadline: String,
    currentTrackIndex: {
      type: Number,
      default: 0,
    },
  };

  static outlets = ["audio-player"];

  // Lifecycle methods
  connect() {
    this.initializeEventListeners();
    this.initializePlayer();
  }

  disconnect() {
    const audio = this.audiotagTarget;

    // Store bound event handlers as instance properties during setup
    if (this.boundAudioEvents) {
      Object.entries(this.boundAudioEvents).forEach(([event, handler]) => {
        audio.removeEventListener(event, handler);
      });
    }

    // Remove progress bar events with properly bound methods
    if (this.boundProgressBarInput) {
      this.progressbarseekTarget.removeEventListener(
        "input",
        this.boundProgressBarInput,
      );
    }

    if (this.boundProgressBarMouseMove) {
      this.progressbarseekTarget.removeEventListener(
        "mousemove",
        this.boundProgressBarMouseMove,
      );
    }

    // Remove tooltip events properly
    if (this.tooltipEnterHandler) {
      this.progressbarseekTarget.removeEventListener(
        "mouseenter",
        this.tooltipEnterHandler,
      );
    }

    if (this.tooltipLeaveHandler) {
      this.progressbarseekTarget.removeEventListener(
        "mouseleave",
        this.tooltipLeaveHandler,
      );
    }

    // Clear timeout to prevent memory leaks
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
  }

  // Initialization methods
  initializePlayer() {
    // Check if duration is already available
    if (this.audiotagTarget.duration) {
      this.updateDurationDisplay(this.audiotagTarget.duration);
    }

    // Initialize UI based on queue state
    this.audioQueueValue.length
      ? this.showFixedController()
      : this.hideFixedController();
  }

  initializeEventListeners() {
    // Audio element events
    this.setupAudioEvents();

    // Progress bar events
    this.setupProgressBarEvents();

    // Seeker tooltip events
    this.setupTooltipEvents();
  }

  setupAudioEvents() {
    const audio = this.audiotagTarget;

    // Store bound handlers for later removal
    this.boundAudioEvents = {
      canplay: () => this.updateDurationDisplay(audio.duration),
      timeupdate: () => this.handleTimeUpdate(),
      play: () => this.updatePlayPauseIcons(true),
      pause: () => this.updatePlayPauseIcons(false),
      ended: () => this.handleTrackEnded(),
      loadedmetadata: () => this.updateDurationDisplay(audio.duration),
      keydown: (e) => this.handleKeyPress(e),
    };

    // Register all audio events
    Object.entries(this.boundAudioEvents).forEach(([event, handler]) => {
      if (event === "loadedmetadata") {
        audio.addEventListener(event, handler, { once: true });
      } else {
        audio.addEventListener(event, handler);
      }
    });
  }

  setupProgressBarEvents() {
    this.boundProgressBarInput = this.handleProgressBarInput.bind(this);
    this.boundProgressBarMouseMove = this.handleProgressBarMouseMove.bind(this);

    this.progressbarseekTarget.addEventListener(
      "input",
      this.boundProgressBarInput,
    );
    this.progressbarseekTarget.addEventListener(
      "mousemove",
      this.boundProgressBarMouseMove,
    );
  }

  setupTooltipEvents() {
    const tooltip = this.progressbarseektooltipTarget;

    this.tooltipEnterHandler = () => {
      tooltip.style.display = "flex";
    };

    this.tooltipLeaveHandler = () => {
      tooltip.style.display = "none";
    };

    this.progressbarseekTarget.addEventListener(
      "mouseenter",
      this.tooltipEnterHandler,
    );
    this.progressbarseekTarget.addEventListener(
      "mouseleave",
      this.tooltipLeaveHandler,
    );
  }

  // Event Handlers
  handleTimeUpdate() {
    const audio = this.audiotagTarget;
    const duration = audio.duration || 1; // Prevent division by zero
    const currentTime = audio.currentTime;

    // Calculate progress as a rounded percentage
    const progress = Math.round((currentTime / duration) * 100) || 0;

    // Update progress bars
    this.progressbarTarget.value = currentTime;
    this.compactProgressbarTarget.value = progress;

    this.updateProgressBarAndTimeDisplays(currentTime);
    this.updatePlayTimeForQueue();
  }

  handleProgressBarInput(event) {
    const skipTo = this.clampNumber(
      event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value,
      0,
      this.audiotagTarget.duration,
    );

    this.progressbarTarget.value = skipTo;
    this.progressbarseekTarget.value = skipTo;
    this.audiotagTarget.currentTime = skipTo;
  }

  handleProgressBarMouseMove(event) {
    this.moveBarSeeker(
      event,
      this.progressbarseekTarget,
      this.progressbarseektooltipTarget,
      this.audiotagTarget,
    );
  }

  handleKeyPress(e) {
    if (e.which === 32) {
      // Space bar
      e.preventDefault();
      e.stopImmediatePropagation();
      this.togglePlayPause();
      return false;
    }
  }

  handleTrackEnded() {
    // Remove the completed track from queue
    const newQueue = [...this.audioQueueValue];
    newQueue.splice(this.currentTrackIndexValue, 1);
    this.audioQueueValue = newQueue;

    if (this.audioQueueValue.length > 0) {
      this.updateQueueDisplay();
      plausible("Audio player - autoplay next track in queue");
      this.playNext();
    } else {
      plausible("Audio player - queue ended");
      this.resetPlayer();
    }
  }

  // UI Update Methods
  updatePlayPauseIcons(isPlaying) {
    if (isPlaying) {
      this.pauseiconTarget.classList.remove("hidden");
      this.expandedPauseiconTarget.classList.remove("hidden");
      this.playiconTarget.classList.add("hidden");
      this.expandedPlayiconTarget.classList.add("hidden");
    } else {
      this.playiconTarget.classList.remove("hidden");
      this.expandedPlayiconTarget.classList.remove("hidden");
      this.pauseiconTarget.classList.add("hidden");
      this.expandedPauseiconTarget.classList.add("hidden");
    }

    // Dispatch custom event when play state changes
    const event = new CustomEvent("audioPlayerStateChanged", {
      detail: {
        isPlaying,
        currentUrl: this.audiotagTarget.src,
      },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  updateDurationDisplay(duration) {
    this.setMaxDuration(
      this.progressbarTarget,
      this.progressbarseekTarget,
      this.controllerdurationstringTarget,
      duration,
    );
  }

  updateQueueDisplay() {
    const fragment = document.createDocumentFragment();

    if (this.audioQueueValue.length === 0) {
      const emptyNode = document.createElement("li");
      emptyNode.textContent = "Ingen uppspelningskö";
      fragment.appendChild(emptyNode);
    } else {
      this.audioQueueValue.forEach((item, index) => {
        const template = this.queueItemTemplateTarget.content.cloneNode(true);
        const listItem = template.querySelector("li");
        const trackSpan = template.querySelector("[data-queue-item-track]");
        const removeButton = template.querySelector("button");

        // Set track data
        trackSpan.textContent = item.headline;
        trackSpan.dataset.audioPlayerTrackIndexParam = index;
        removeButton.dataset.audioPlayerTrackIndexParam = index;

        // Handle current track styling
        if (index === this.currentTrackIndexValue) {
          trackSpan.classList.add("text-[#CC3333]");
        }

        fragment.appendChild(listItem);
      });
    }

    // Clear and update queue list
    this.queueListTarget.innerHTML = "";
    this.queueListTarget.appendChild(fragment);
  }

  // Player Control Methods
  togglePlayPause() {
    const audio = this.audiotagTarget;
    audio.paused || audio.ended ? this.play() : this.pause();
  }

  play() {
    plausible("Audio player - play");
    this.audiotagTarget.play();
  }

  pause() {
    plausible("Audio player - pause");
    this.audiotagTarget.pause();
  }

  playNext() {
    try {
      const track = this.audioQueueValue[this.currentTrackIndexValue];
      if (!track) {
        this.hideFixedController();
        throw new Error("No track found at current index");
      }

      this.resetProgressBar();
      this.loadTrack(track);

      // Add Promise handling
      this.audiotagTarget.play().catch((error) => {
        console.warn("Audio playback failed:", error.message);

        // If playback was aborted, try again after a short delay
        if (error.name === "AbortError") {
          setTimeout(() => {
            // Try again only if we're still on the same track
            const currentTrack =
              this.audioQueueValue[this.currentTrackIndexValue];
            if (currentTrack && currentTrack.url === track.url) {
              this.audiotagTarget.play().catch(() => {
                // If it fails again, require user interaction
                console.error(
                  "Playback failed after retry, user interaction required",
                );
              });
            }
          }, 300);
        }
      });
    } catch (e) {
      console.error("Error playing next track:", e);
    }
  }

  playTrack(event) {
    event.stopPropagation();
    const trackIndex = parseInt(
      event.currentTarget.dataset.audioPlayerTrackIndexParam,
    );

    plausible("Audio player - track selected from queue");

    this.currentTrackIndexValue = trackIndex;
    this.updateQueueDisplay();
    this.playNext();
  }

  removeFromQueue(event) {
    event.preventDefault();
    event.stopPropagation();

    const trackIndex = parseInt(
      event.currentTarget.dataset.audioPlayerTrackIndexParam,
    );
    const newQueue = [...this.audioQueueValue];
    newQueue.splice(trackIndex, 1);

    if (trackIndex === this.currentTrackIndexValue) {
      if (newQueue.length > 0) {
        this.currentTrackIndexValue = Math.min(trackIndex, newQueue.length - 1);
        this.audioQueueValue = newQueue;
        this.playNext();
      } else {
        this.stopPlayingAndClose();
      }
    } else {
      if (trackIndex < this.currentTrackIndexValue) {
        this.currentTrackIndexValue--;
      }
      this.audioQueueValue = newQueue;
    }
  }

  skipForward(event) {
    event.preventDefault();
    event.stopPropagation();

    const skipAmount = 15;
    const newTime = Math.min(
      this.audiotagTarget.currentTime + skipAmount,
      this.audiotagTarget.duration,
    );

    this.audiotagTarget.currentTime = newTime;
  }

  skipBackward(event) {
    event.preventDefault();
    event.stopPropagation();

    const skipAmount = 15;
    const newTime = Math.max(this.audiotagTarget.currentTime - skipAmount, 0);

    this.audiotagTarget.currentTime = newTime;
  }

  // View toggling
  expandView(event) {
    const clickExceptions = [
      '[data-audio-player-target="playbutton"]',
      '[data-audio-player-target="closeButton"]',
      '[data-share-popup-target="button"]',
      "a[href]",
    ];

    // Don't toggle if clicking these elements
    if (clickExceptions.some((selector) => event.target.closest(selector))) {
      return;
    }

    this.showExpandedView();
  }

  showCompactView() {
    this.expandedViewTarget.classList.add("hidden");
    this.compactViewTarget.classList.remove("hidden");
    this.playerContainerTarget.classList.remove("h-auto");
    this.compactProgressbarContainerTarget.classList.remove("hidden");
  }

  showExpandedView() {
    plausible("Audio player - expanded view opened");
    this.expandedViewTarget.classList.remove("hidden");
    this.compactViewTarget.classList.add("hidden");
    this.playerContainerTarget.classList.add("h-auto");
    this.compactProgressbarContainerTarget.classList.add("hidden");
    this.expandedHeadlineTarget.textContent = this.headlineTarget.textContent;
  }

  // Controller visibility
  hideFixedController() {
    if (!this.element.classList.contains("hidden")) {
      this.hideTimeout = setTimeout(
        () => this.element.classList.add("hidden"),
        150 /* magic number from tailwind transition */,
      );
    }
    this.element.classList.replace("bottom-0", "-bottom-40");
  }

  showFixedController() {
    if (this.hideTimeout) clearTimeout(this.hideTimeout);
    this.element.classList.remove("hidden");
    this.element.classList.replace("-bottom-40", "bottom-0");
  }

  // Helper methods
  loadTrack(track) {
    const audio = this.audiotagTarget;

    // First pause and reset the current audio
    audio.pause();

    // Add error handling for track loading
    const errorHandler = (e) => {
      console.error(`Error loading track: ${track.url}`, e);
      audio.removeEventListener("error", errorHandler);

      // Skip to next track if available
      const newQueue = [...this.audioQueueValue];
      newQueue.splice(this.currentTrackIndexValue, 1);

      if (newQueue.length > 0) {
        this.audioQueueValue = newQueue;
        this.playNext();
      } else {
        this.resetPlayer();
      }
    };

    // Remove any existing error handlers
    audio.removeEventListener("error", errorHandler);
    // Add new error handler
    audio.addEventListener("error", errorHandler, { once: true });

    // Use load() to reset the media element before setting a new source
    audio.src = track.url;
    audio.load();
    audio.currentTime = track.timeElapsed ?? 0;

    // Update UI elements with track info
    this.headlineTarget.textContent = track.headline;
    this.expandedHeadlineTarget.textContent = track.headline;
    this.compactCoverImageTarget.src = track.coverImage ?? "";
    this.expandedCoverImageTarget.src = track.coverImage ?? "";
    this.compactVignetteTarget.textContent = track.vignette ?? "Vignette";
    this.expandedVignetteTarget.textContent = track.vignette ?? "Vignette";
  }

  resetProgressBar() {
    this.progressbarTarget.value = 0;
    this.progressbarseekTarget.value = 0;
    this.compactProgressbarTarget.value = 0;
  }

  resetPlayer() {
    this.audiotagTarget.currentTime = 0;
    this.audiotagTarget.pause();
    this.hideFixedController();
  }

  stopPlayingAndClose() {
    this.audiotagTarget.pause();
    this.audioQueueValue = [];

    // Workaround to ensure pause event fires
    setTimeout(() => {
      this.audiotagTarget.src = "";
    }, 10);

    this.audiotagTarget.currentTime = 0;
    this.hideFixedController();
  }

  updatePlayTimeForQueue() {
    try {
      if (!this.audiotagTarget.src || !this.hasAudioQueueValue) {
        return;
      }

      const currentSource = this.audiotagTarget.src;
      const timeElapsed = this.audiotagTarget.currentTime;

      this.audioQueueValue = this.audioQueueValue.map((el) =>
        el.url === currentSource ? { ...el, timeElapsed } : el,
      );
    } catch (error) {
      // Silent fail - non-critical feature
    }
  }

  // Observer method
  audioQueueValueChanged() {
    this.updateQueueDisplay();
    this.audioQueueValue.length
      ? this.showFixedController()
      : this.hideFixedController();
  }

  // Player state methods
  isCurrentlyPlayingAudio() {
    const audio = this.audiotagTarget;
    return !(audio.currentTime === 0 || audio.paused || audio.ended);
  }

  // Add method to check if a URL is currently playing
  isUrlPlaying(url) {
    return this.audiotagTarget.src === url && !this.audiotagTarget.paused;
  }

  // Enhanced queue management
  addToQueue(track, playImmediately = true) {
    const existingTrackIndex = this.audioQueueValue.findIndex(
      (t) => t.url === track.url,
    );

    if (existingTrackIndex > -1) {
      // Remove existing track
      this.audioQueueValue = [
        ...this.audioQueueValue.slice(0, existingTrackIndex),
        ...this.audioQueueValue.slice(existingTrackIndex + 1),
      ];
    }

    // Add track to beginning of queue
    this.audioQueueValue = [track, ...this.audioQueueValue];

    if (playImmediately) {
      this.currentTrackIndexValue = 0;
      this.playNext();
    }
  }
}
