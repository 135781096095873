import { Controller } from "@hotwired/stimulus";
import Hls, { Events } from "hls.js";

export default class extends Controller {
  static targets = [
    "videocontainer",
    "videotag",
    "initialplaybutton",
    "initialplaydurationinfo",
    "playbutton",
    "pausebutton",
    "controllers",
    "controllerfullscreen",
    "controllermute",
    "controllerunmute",
    "controllerprogressstring",
    "controllerdurationstring",
    "onscreencontrols",
    "progressbarcontainer",
    "progressbar",
    "progressbarseek",
    "progressbarseektooltip",
  ];

  static values = { manifestUrl: String, hlsId: String };

  connect() {
    this.hasBeenStartedAtLeastOnce = false;
    this.mousemoveTimeout = null;

    if (Hls.isSupported() && this.hasManifestUrlValue) {
      this.hls = new Hls();
      this.hls.loadSource(this.manifestUrlValue);

      this.hls.on(Events.MANIFEST_LOADED, () =>
        this.hls.attachMedia(this.videotagTarget),
      );
    }

    // Play event occured
    this.videotagTarget.addEventListener(
      "play",
      () => {
        this.showPauseButton();
        this.showOnscreenControls();
        this.videoHasBeenStarted();

        this.startFadeTimerForControls();
        this.startFadeTimerForOnscreenControls();

        this.videotagTarget.focus();

        if (!this.hasBeenStartedAtLeastOnce) {
          plausible("Video Playback Started", {
            props: {
              video_id: this.hlsIdValue,
            },
          });
        }

        this.hasBeenStartedAtLeastOnce = true;
      },
      false,
    );

    // Pause event occured
    this.videotagTarget.addEventListener(
      "pause",
      () => {
        this.stopFadeTimerForControls();
        this.stopFadeTimerForOnscreenControls();
        this.showPlayButton();
        this.showOnscreenControls();
        this.showControls();
        this.videotagTarget.focus();
      },
      false,
    );

    // Volume change event occured
    this.videotagTarget.addEventListener(
      "volumechange",
      () => {
        const newVolume = this.videotagTarget.volume * 100;
        if (this.videotagTarget.muted || newVolume == 0) {
          this.showControllerUnmuteIcon();
        } else {
          this.showControllerMuteIcon();
        }

        // We want to show/highlight the controls again (and start fading)
        // when clicking mute/unmute just so the user can see the click had an effect
        this.showOnscreenControls();
        this.showControls();
        this.startFadeTimerForControls();
        this.startFadeTimerForOnscreenControls();

        this.videotagTarget.focus();
      },
      false,
    );

    // When we can play, we want to see the duration
    this.videotagTarget.addEventListener(
      "canplay",
      () => {
        this.setDuration();
      },
      false,
    );

    // If we missed the canplay event and duration is already available
    if (this.videotagTarget.duration) {
      this.setDuration();
    }

    // Play/pause
    this.initialplaybuttonTarget.addEventListener("click", (e) => {
      this.togglePlayPause();
      this.videotagTarget.focus();
    });
    this.playbuttonTarget.addEventListener("click", (e) => {
      this.togglePlayPause();
      this.videotagTarget.focus();
    });
    this.pausebuttonTarget.addEventListener("click", (e) => {
      this.togglePlayPause();
      this.videotagTarget.focus();
    });
    this.videotagTarget.addEventListener("click", (e) => {
      this.toggleAllControlsVisibility();
    });

    // Space bar pause
    this.videotagTarget.addEventListener("keydown", (e) => {
      if (e.which == 32) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.videotagTarget.paused
          ? this.videotagTarget.play()
          : this.videotagTarget.pause();
        return false;
      }
    });

    // Show pause button and controllers when moving mouse
    this.videotagTarget.addEventListener("mousemove", (event) => {
      // Hack to make "this" available in the setTimeout callback
      const that = this;
      if (!that.mousemoveTimeout) {
        that.mousemoveTimeout = setTimeout(() => {
          if (that.hasBeenStartedAtLeastOnce) {
            that.showOnscreenControls();
            that.showControls();
            if (!that.videotagTarget.paused) {
              that.startFadeTimerForOnscreenControls();
              that.startFadeTimerForControls();
            }
          }
          clearTimeout(that.mousemoveTimeout);
          that.mousemoveTimeout = null;
        }, 100);
      }
    });
    this.onscreencontrolsTarget.addEventListener("mousemove", (event) => {
      const hoveringControllers = this.areWeHoveringControls();
      if (this.hasBeenStartedAtLeastOnce && hoveringControllers) {
        this.stopFadeTimerForControls();
        this.stopFadeTimerForOnscreenControls();
        this.showOnscreenControls();
        this.showControls();
      }
    });
    // Show pause button and controllers when moving mouse over controllers but don't start to fade
    this.controllersTarget.addEventListener("mousemove", (event) => {
      const hoveringControllers = this.areWeHoveringControls();
      if (this.hasBeenStartedAtLeastOnce && hoveringControllers) {
        this.stopFadeTimerForControls();
        this.stopFadeTimerForOnscreenControls();
        this.showOnscreenControls();
        this.showControls();
      }
    });

    // Track the mouse to see if it is hovering the controls
    this.controllersTarget.addEventListener("mouseenter", (event) => {
      this.controllersTarget.setAttribute("data-hovering", "true");
    });
    this.controllersTarget.addEventListener("mouseleave", (event) => {
      this.controllersTarget.setAttribute("data-hovering", "false");
      if (this.hasBeenStartedAtLeastOnce) {
        this.showOnscreenControls();
        this.showControls();
        if (!this.videotagTarget.paused) {
          this.startFadeTimerForOnscreenControls();
          this.startFadeTimerForControls();
        }
      }
    });
    // Track the mouse to see if it is hovering the controls
    this.onscreencontrolsTarget.addEventListener("mouseenter", (event) => {
      this.onscreencontrolsTarget.setAttribute("data-hovering", "true");
    });
    this.onscreencontrolsTarget.addEventListener("mouseleave", (event) => {
      // A mouseleave event will be generated even if the user does not move the mouse,
      // but if the opacity will be 0 and the element "disappears"
      if (!this.onscreencontrolsTarget.classList.contains("fading")) {
        this.onscreencontrolsTarget.setAttribute("data-hovering", "false");
        if (this.hasBeenStartedAtLeastOnce) {
          this.showOnscreenControls();
          this.showControls();
          if (!this.videotagTarget.paused) {
            this.startFadeTimerForOnscreenControls();
            this.startFadeTimerForControls();
          }
        }
      }
    });

    // Mute/unmute
    this.controllermuteTarget.addEventListener("click", (e) => {
      this.videotagTarget.muted = true;
      this.videotagTarget.focus();
    });
    this.controllerunmuteTarget.addEventListener("click", (e) => {
      this.videotagTarget.muted = false;
      this.videotagTarget.focus();
    });

    // Fullscreen
    this.videotagTarget.addEventListener("dblclick", (event) => {
      this.stopTogglePlayPauseTimer();
      this.toggleFullScreen();
      this.videotagTarget.focus();
    });
    this.controllerfullscreenTarget.addEventListener("click", (e) => {
      this.toggleFullScreen();
      this.videotagTarget.focus();
    });

    // Progress bar
    this.videotagTarget.addEventListener("timeupdate", (e) => {
      const duration = this.videotagTarget.duration;
      if (!duration) {
        return;
      }

      const currentTime = Math.round(this.videotagTarget.currentTime);
      this.progressbarTarget.value = currentTime;
      this.progressbarseekTarget.value = currentTime;
      this.setCurrentTime();
    });

    this.progressbarseekTarget.addEventListener("input", (event) => {
      const duration = this.videotagTarget.duration;
      if (!duration) {
        return;
      }
      const roundedDuration = Math.round(duration);
      let skipTo = event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value;
      if (skipTo < 0) {
        skipTo = 0;
      }
      if (skipTo > roundedDuration) {
        skipTo = roundedDuration;
      }
      this.videotagTarget.currentTime = skipTo;
      this.progressbarTarget.value = skipTo;
      this.progressbarseekTarget.value = skipTo;
    });

    this.progressbarseekTarget.addEventListener("mousemove", (event) => {
      const duration = this.videotagTarget.duration;
      if (!duration) {
        return;
      }
      const roundedDuration = Math.round(duration);
      let skipTo = Math.round(
        (event.offsetX / event.target.clientWidth) *
          parseInt(event.target.getAttribute("max"), 10),
      );
      if (skipTo < 0) {
        skipTo = 0;
      }
      if (skipTo > roundedDuration) {
        skipTo = roundedDuration;
      }
      this.progressbarseekTarget.setAttribute("data-seek", skipTo);

      const skipToParts = this.getTimeInHoursMinutesAndSeconds(skipTo);
      let skipToStr = "";
      // XX:XX:XX or XX:XX
      // We only want XX:XX:XX if there is at least one hour
      if (roundedDuration > 3600) {
        skipToStr =
          String(skipToParts["hours"]).padStart(2, "0") +
          ":" +
          String(skipToParts["minutes"]).padStart(2, "0") +
          ":" +
          String(skipToParts["seconds"]).padStart(2, "0");
      } else {
        skipToStr =
          String(skipToParts["minutes"]).padStart(2, "0") +
          ":" +
          String(skipToParts["seconds"]).padStart(2, "0");
      }
      this.progressbarseektooltipTarget.textContent = skipToStr;

      // We don't want the toolbar to go too far or too far right
      const offset = this.getOffset(this.progressbarseekTarget);
      const halfSize = this.progressbarseektooltipTarget.clientWidth / 2;
      let leftPixels = event.pageX - offset - halfSize;
      if (leftPixels + halfSize < 0) {
        leftPixels = -halfSize;
      }
      if (leftPixels + halfSize > event.target.clientWidth) {
        leftPixels = event.target.clientWidth - halfSize;
      }

      this.progressbarseektooltipTarget.style.left = `${leftPixels}px`;
    });
    this.progressbarseekTarget.addEventListener("mouseenter", (event) => {
      this.progressbarseektooltipTarget.style.display = "flex";
    });
    this.progressbarseekTarget.addEventListener("mouseleave", (event) => {
      this.progressbarseektooltipTarget.style.display = "none";
    });
  }

  // A very annoying way to get the x pos where the target starts...
  getOffset(initialElement) {
    let offsetTotal = 0;
    let elem = initialElement;
    while (elem) {
      offsetTotal += elem.offsetLeft;
      elem = elem.offsetParent;
    }
    return offsetTotal;
  }

  setDuration() {
    const duration = this.videotagTarget.duration;
    if (!duration) {
      return;
    }
    const roundedDuration = Math.round(duration);
    if (!this.progressbarTarget.getAttribute("max")) {
      this.progressbarTarget.setAttribute("max", roundedDuration);
    }
    if (!this.progressbarseekTarget.getAttribute("max")) {
      this.progressbarseekTarget.setAttribute("max", roundedDuration);
    }
    const durationParts = this.getTimeInHoursMinutesAndSeconds(roundedDuration);
    let durationStr = "";
    if (durationParts["hours"] > 0) {
      durationStr =
        String(durationParts["hours"]).padStart(2, "0") +
        ":" +
        String(durationParts["minutes"]).padStart(2, "0") +
        ":" +
        String(durationParts["seconds"]).padStart(2, "0");
    } else {
      durationStr =
        String(durationParts["minutes"]).padStart(2, "0") +
        ":" +
        String(durationParts["seconds"]).padStart(2, "0");
    }
    this.controllerdurationstringTarget.textContent = durationStr;

    const durationMoreHumanFriendly =
      this.getTimeInMoreHumanFriendlyFormat(durationParts);
    this.initialplaydurationinfoTarget.textContent = durationMoreHumanFriendly;
  }

  setCurrentTime() {
    const duration = this.videotagTarget.duration;
    if (!duration) {
      return;
    }
    const roundedDuration = Math.round(duration);
    const currentTime = this.videotagTarget.currentTime ?? 0;
    const currentTimeParts = this.getTimeInHoursMinutesAndSeconds(currentTime);

    let currentTimeStr = "";
    // XX:XX:XX or XX:XX
    // We only want XX:XX:XX if there is at least one hour
    if (roundedDuration > 3600) {
      currentTimeStr =
        String(currentTimeParts["hours"]).padStart(2, "0") +
        ":" +
        String(currentTimeParts["minutes"]).padStart(2, "0") +
        ":" +
        String(currentTimeParts["seconds"]).padStart(2, "0");
    } else {
      currentTimeStr =
        String(currentTimeParts["minutes"]).padStart(2, "0") +
        ":" +
        String(currentTimeParts["seconds"]).padStart(2, "0");
    }

    this.controllerprogressstringTarget.textContent = currentTimeStr;
  }

  getTimeInHoursMinutesAndSeconds(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time / 60 - hours * 60);
    const seconds = Math.floor(time - hours * 3600 - minutes * 60);
    return {
      seconds: seconds,
      minutes: minutes,
      hours: hours,
    };
  }

  // Will return e.g. "1 h 5 min", "3 min" or "8 sek"
  getTimeInMoreHumanFriendlyFormat(parts) {
    if (parts["hours"] > 0) {
      return `${parts["hours"]} h ${parts["minutes"]} min`;
    } else if (parts["minutes"] > 0) {
      return `${parts["minutes"]} min`;
    } else {
      return `${parts["seconds"]} sek`;
    }
  }

  toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      this.videocontainerTarget.requestFullscreen();
    }
  }

  togglePlayPause() {
    // We do this timeout thing because we want to be able to double click the video for fullscreen
    // And then we don't want play and pause events to happen
    // The time between clicks in a double click is not standardized
    // However, the worst thing is that if a person does a very slow double click (like 350 ms),
    // the fullscreen toggle will happen (as it should) and play/pause will toggle (but it shouldn't really)
    clearTimeout(this.togglePlayPauseTimeOut);
    this.togglePlayPauseTimeOut = setTimeout(() => {
      if (this.videotagTarget.paused || this.videotagTarget.ended) {
        this.videotagTarget.play();
      } else {
        this.videotagTarget.pause();
      }
    }, 300);
  }

  toggleAllControlsVisibility() {
    // When touch is enabled (like in mobile), there will first be a mousemove event and then a click event
    if (this.mousemoveTimeout) {
      clearTimeout(this.mousemoveTimeout);
      this.mousemoveTimeout = null;
    }

    if (this.controllersTarget.classList.contains("fading")) {
      this.showOnscreenControls();
      this.showControls();
      if (!this.videotagTarget.paused && !this.videotagTarget.ended) {
        this.startFadeTimerForControls();
        this.startFadeTimerForOnscreenControls();
      }
    } else {
      this.onscreencontrolsTarget.classList.add("fading");
      this.onscreencontrolsTarget.classList.add("hidden");

      this.controllersTarget.classList.add("fading");
      this.controllersTarget.classList.add("hidden");
    }
  }

  videoHasBeenStarted() {
    this.initialplaybuttonTarget.classList.add("hidden");
  }

  areWeHoveringControls() {
    return (
      this.controllersTarget.getAttribute("data-hovering") == "true" ||
      this.onscreencontrolsTarget.getAttribute("data-hovering") == "true"
    );
  }

  stopTogglePlayPauseTimer() {
    if (this.togglePlayPauseTimeOut) {
      clearTimeout(this.togglePlayPauseTimeOut);
    }
  }

  showPauseButton() {
    if (this.hasBeenStartedAtLeastOnce) {
      this.pausebuttonTarget.classList.remove("hidden");
      this.playbuttonTarget.classList.add("hidden");
    }
  }

  showControllerUnmuteIcon() {
    this.controllermuteTarget.classList.add("hidden");
    this.controllerunmuteTarget.classList.remove("hidden");
  }

  showControllerMuteIcon() {
    this.controllermuteTarget.classList.remove("hidden");
    this.controllerunmuteTarget.classList.add("hidden");
  }

  showOnscreenControls() {
    if (this.hasBeenStartedAtLeastOnce) {
      this.onscreencontrolsTarget.classList.remove("fading");
      this.onscreencontrolsTarget.classList.remove("hidden");
    }
  }

  showControls() {
    if (this.hasBeenStartedAtLeastOnce) {
      this.controllersTarget.classList.remove("fading");
      this.controllersTarget.classList.remove("hidden");
    }
  }

  showPlayButton() {
    this.pausebuttonTarget.classList.add("hidden");
    this.playbuttonTarget.classList.remove("hidden");
  }

  startFadeTimerForOnscreenControls() {
    clearTimeout(this.fadeTimerForOnscreenControls);
    this.onscreencontrolsTarget.classList.remove("fading");
    const that = this;
    this.fadeTimerForOnscreenControls = setTimeout(() => {
      this.onscreencontrolsTarget.classList.add("fading");
      setTimeout(() => {
        if (that.onscreencontrolsTarget.classList.contains("fading")) {
          that.onscreencontrolsTarget.classList.add("hidden");
        }
      }, 500);
    }, 800);
  }

  startFadeTimerForControls() {
    clearTimeout(this.fadeTimerForControls);
    this.controllersTarget.classList.remove("fading");
    const that = this;
    this.fadeTimerForControls = setTimeout(() => {
      this.controllersTarget.classList.add("fading");
      setTimeout(() => {
        if (that.controllersTarget.classList.contains("fading")) {
          that.controllersTarget.classList.add("hidden");
        }
      }, 500);
    }, 800);
  }

  stopFadeTimerForControls() {
    if (this.fadeTimerForControls) {
      clearTimeout(this.fadeTimerForControls);
    }
  }

  stopFadeTimerForOnscreenControls() {
    if (this.fadeTimerForOnscreenControls) {
      clearTimeout(this.fadeTimerForOnscreenControls);
    }
  }
}
