import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["viewPort", "nextBtn", "prevBtn", "card"];

  connect() {
    this.viewPortTarget.addEventListener("scrollend", () =>
      this.checkBtnVisibility()
    );

    this.cardTargets.forEach((card) =>
      card.addEventListener("focus", (e) => this.targetCard(e.target))
    );

    this.checkBtnVisibility();
  }

  checkBtnVisibility() {
    const maxScroll =
      this.viewPortTarget.scrollWidth - this.viewPortTarget.clientWidth;

    // current scroll is more or equal than max vertical scroll distance
    const atMaxScroll = this.viewPortTarget.scrollLeft >= maxScroll;
    atMaxScroll
      ? this.nextBtnTarget.classList.add("hidden")
      : this.nextBtnTarget.classList.remove("hidden");

    this.viewPortTarget.scrollLeft <= 0
      ? this.prevBtnTarget.classList.add("hidden")
      : this.prevBtnTarget.classList.remove("hidden");
  }

  scrollToNext() {
    this.scroll(1);
  }

  scrollToPrev() {
    this.scroll(-1);
  }

  scroll(add) {
    const card = this.currentTarget();
    const idx = this.cardTargets.indexOf(card);
    const target = this.cardTargets[idx + add];

    this.targetCard(target);
    this.currentTarget().scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "center",
    });
  }

  currentTarget() {
    const card = this.cardTargets.find(
      (card) => card.dataset.targeted == "true"
    );

    if (!!card) return card;

    this.cardTarget.dataset.targeted = true;
    return this.cardTarget;
  }

  targetCard(card) {
    if (card) {
      this.cardTargets.forEach((card) => (card.dataset.targeted = false));
      card.dataset.targeted = true;
      card.focus({ focusVisible: true });
    }
  }
}
