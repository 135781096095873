import AbstractPlayerController from "./abstract_player_controller";

export default class extends AbstractPlayerController {
  static targets = ["audiotag", "playicon", "pauseicon"];

  static values = {
    audioUrl: String,
    audioHeadline: String,
    coverImage: String,
    vignette: String,
    articleUrl: String,
    playerId: String,
    isPlaying: Boolean,
  };

  static outlets = ["audio-player"];

  connect() {
    this.updatePlayerState();
  }

  handlePlayerStateChange(event) {
    const { isPlaying, currentUrl } = event.detail;
    const isMyTrack = currentUrl === this.audioUrlValue;

    this.isPlayingValue = isMyTrack && isPlaying;
    this.updatePlayerState();
  }

  updatePlayerState() {
    if (this.isPlayingValue) {
      this.playiconTarget.classList.add("hidden");
      this.pauseiconTarget.classList.remove("hidden");
    } else {
      this.playiconTarget.classList.remove("hidden");
      this.pauseiconTarget.classList.add("hidden");
    }
  }

  leadPlay() {
    if (this.isPlayingValue) {
      // If this is the currently playing track, pause it
      this.audioPlayerOutlet.pause();
    } else {
      // Play this track
      const track = {
        url: this.audioUrlValue,
        headline: this.audioHeadlineValue,
        coverImage: this.coverImageValue,
        vignette: this.vignetteValue,
        articleUrl: this.articleUrlValue,
        playerId: this.playerIdValue,
      };

      this.audioPlayerOutlet.addToQueue(track, true);
    }
  }

  handlePlay() {
    this.isPlayingValue = true;
    this.updatePlayerState();
  }

  handlePause() {
    this.isPlayingValue = false;
    this.updatePlayerState();
  }

  handleEnded() {
    this.isPlayingValue = false;
    this.updatePlayerState();
  }
}
