import AbstractPlayerController from './abstract_player_controller'
import Hls, { Events } from 'hls.js'

export default class VideoPlayerController extends AbstractPlayerController {
  static targets = [
    'videoTag',
    'videoContainer',
    'durationShortInfo',
    'progressBar',
    'bufferBar',
    'progressInput',
    'progressInfo',
    'controls',
    'container',
    'spinner',
    'volumeSlider',
  ]

  static values = {
    manifestUrl: String,
    state: { type: String, default: 'not-started' },
    muted: { type: Boolean, default: false },
  }

  // Core variables
  hls = null
  lastVolume = 1
  controlsVisible = false
  controlsTimeoutId = null
  isSeeking = false

  // Core variables
  hls = null
  lastVolume = 1
  controlsVisible = false
  controlsTimeoutId = null
  isSeeking = false

  connect() {
    // Store bound event handlers as properties
    this.boundHandleTouch = this.handleTouch.bind(this)

    this.initPlayer()
    this.addEventListeners()
  }

  disconnect() {
    this.removeEventListeners()
    this.cleanupResources()
  }

  initPlayer() {
    // Initialize HLS if supported
    if (Hls.isSupported() && this.hasManifestUrlValue) {
      this.hls = new Hls({
        autoStartLoad: true,
        maxBufferLength: 30,
        maxMaxBufferLength: 60,
        maxBufferSize: 60 * 1000 * 1000, // 60MB
      })

      this.hls.loadSource(this.manifestUrlValue)
      this.hls.on(Events.FRAG_BUFFERED, this.updateBuffer.bind(this))
      this.hls.on(Events.MANIFEST_LOADED, () => {
        this.hls.attachMedia(this.videoTagTarget)
      })
      this.hls.on(Events.ERROR, this.handleHlsError.bind(this))
    } else if (
      this.videoTagTarget.canPlayType('application/vnd.apple.mpegurl')
    ) {
      this.videoTagTarget.src = this.manifestUrlValue
    }

    // Initialize state
    this.videoTagTarget.volume = 1
    this.mutedValue = false

    // Initialize debounced controls
    this.showControlsDebounced = this.debounce(
      this.showControls.bind(this),
      100,
    )
  }

  // Event handlers
  addEventListeners() {
    this.videoTagTarget.addEventListener('keydown', (e) => {
      if (e.code === 'Space') {
        e.preventDefault()
        this.togglePlayPause(e)
      }
    })

    // Touch handlers with passive option
    this.containerTarget.addEventListener('touchstart', this.boundHandleTouch, {
      passive: false,
    })
    this.containerTarget.addEventListener('touchend', this.boundHandleTouch, {
      passive: false,
    })
  }

  removeEventListeners() {
    this.containerTarget.removeEventListener(
      'touchstart',
      this.boundHandleTouch,
    )
    this.containerTarget.removeEventListener('touchend', this.boundHandleTouch)
  }

  handleHlsError(event, data) {
    if (!data.fatal) return

    switch (data.type) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        this.hls.startLoad()
        break
      case Hls.ErrorTypes.MEDIA_ERROR:
        this.hls.recoverMediaError()
        break
      default:
        this.cleanupHls()
        break
    }
  }

  // Player state
  ready() {
    this.setCurrentTime(this.videoTagTarget.currentTime)
    this.hideSpinner()
  }

  notReady() {
    this.showSpinner()
  }

  registerState(event) {
    this.stateValue = event.type
  }

  // Media time handling
  setDuration(event) {
    const duration = event.target.duration
    if (!isNaN(duration)) {
      // Update duration display
      this.updateTargets(this.durationShortInfoTargets, (target) => {
        target.textContent = this.formatDurationToString(duration)
      })

      // Set progress bar max values
      this.updateTargets(this.progressBarTargets, (bar) => {
        bar.setAttribute('max', duration)
      })
      this.updateTargets(this.progressInputTargets, (input) => {
        input.setAttribute('max', duration)
      })
    }
  }

  progress() {
    if (this.videoTagTarget.readyState <= 0) return

    const currentTime = this.videoTagTarget.currentTime
    const duration = this.videoTagTarget.duration

    if (!isNaN(currentTime) && !isNaN(duration) && duration > 0) {
      this.setCurrentTime(currentTime)
      this.updateBuffer()
    }
  }

  setCurrentTime(time) {
    if (isNaN(time)) return

    // Update progress bar
    this.updateTargets(this.progressBarTargets, (bar) => {
      bar.value = time
    })

    // Update range input
    this.updateTargets(this.progressInputTargets, (input) => {
      input.value = time
    })

    // Update time display
    this.updateTargets(this.progressInfoTargets, (info) => {
      info.textContent = this.formatDurationToString(time)
    })
  }

  updateBuffer() {
    const video = this.videoTagTarget
    const duration = video.duration

    if (!duration || !this.hasBufferBarTarget) return

    const { buffered } = video
    let maxBuffered = 0

    for (let i = 0; i < buffered.length; i++) {
      maxBuffered = Math.max(maxBuffered, buffered.end(i))
    }

    this.updateTargets(this.bufferBarTargets, (bar) => {
      bar.max = duration
      bar.value = maxBuffered
    })
  }

  // Player controls
  togglePlayPause(event) {
    if (event) event.preventDefault()

    if (this.stateValue === 'play') {
      this.videoTagTarget.pause()
    } else {
      this.playVideo()
    }
  }

  playVideo() {
    const playPromise = this.videoTagTarget.play()

    if (playPromise !== undefined) {
      this.showSpinner()
      playPromise
        .then(() => this.hideSpinner())
        .catch((error) => {
          console.warn('Playback failed:', error)
          this.hideSpinner()
          this.stateValue = 'paused'
        })
    }
  }

  skipTo(event) {
    const value = event.target.dataset.seek || event.target.value
    const skipTo = Math.max(
      0,
      Math.min(parseFloat(value), this.videoTagTarget.duration),
    )
    this.videoTagTarget.currentTime = skipTo
    this.setCurrentTime(skipTo)
  }

  async fullScreen() {
    try {
      if (this.isIOS()) {
        // iOS specific fullscreen handling
        if (document.webkitFullscreenElement) {
          await this.videoTagTarget.webkitExitFullscreen()
        } else {
          await this.videoTagTarget.webkitEnterFullscreen()
        }
      } else {
        // Standard Fullscreen API for other devices
        if (document.fullscreenElement) {
          await document.exitFullscreen()
        } else {
          await this.videoContainerTarget.requestFullscreen()
          if (screen.orientation) {
            try {
              await screen.orientation.lock('landscape')
            } catch (err) {
              console.warn('Orientation lock not supported')
            }
          }
        }
      }
    } catch (error) {
      console.warn('Fullscreen API error:', error)
    }
  }

  // Volume controls
  registerVolumeChange() {
    this.mutedValue = this.videoTagTarget.volume === 0
    if (this.hasVolumeSliderTarget) {
      this.volumeSliderTarget.value = this.videoTagTarget.volume
    }
  }

  setVolume(event) {
    this.videoTagTarget.volume = event.target.value
    this.mutedValue = this.videoTagTarget.volume === 0
  }

  toggleMute() {
    if (this.videoTagTarget.volume > 0) {
      this.lastVolume = this.videoTagTarget.volume
      this.videoTagTarget.volume = 0
    } else {
      this.videoTagTarget.volume = this.lastVolume
    }
    this.mutedValue = this.videoTagTarget.volume === 0
  }

  // UI controls visibility
  handleMouseMove() {
    this.showControlsWithTimeout()
  }

  handleTouch(event) {
    // Prevent default only if we're showing controls
    if (!this.controlsVisible) {
      event.preventDefault()
      this.showControlsWithTimeout()

      // If we're not started or paused, also trigger play
      if (this.stateValue === 'not-started' || this.stateValue === 'paused') {
        this.playVideo()
      }
    }
  }

  showControlsWithTimeout() {
    this.showControlsDebounced()

    if (this.controlsTimeoutId) {
      clearTimeout(this.controlsTimeoutId)
    }

    this.controlsTimeoutId = setTimeout(() => {
      if (this.stateValue === 'play') {
        this.hideControls()
      }
    }, 2500)
  }

  showControls() {
    if (this.controlsVisible) return

    const controlsClassList = this.controlsTarget.classList
    controlsClassList.add('opacity-100', 'visible', 'pointer-events-auto')
    controlsClassList.remove('invisible', 'pointer-events-none')
    this.containerTarget.classList.remove('cursor-none')
    this.controlsVisible = true
  }

  hideControls() {
    if (this.stateValue !== 'play' || !this.controlsVisible) return

    this.controlsTarget.classList.remove(
      'opacity-100',
      'visible',
      'pointer-events-auto',
    )
    this.controlsTarget.classList.add('invisible', 'pointer-events-none')
    this.containerTarget.classList.add('cursor-none')
    this.controlsVisible = false
  }

  // UI elements
  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.spinnerTarget.classList.add('flex')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('flex')
  }

  // Utility methods
  isIOS() {
    const ua = window.navigator.userAgent.toLowerCase()
    return (
      /ipad|iphone|ipod/.test(ua) ||
      (ua.includes('mac') && 'ontouchend' in document)
    )
  }

  debounce(func, wait) {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }

  updateTargets(targets, callback) {
    if (!targets || !targets.length) return
    targets.forEach(callback)
  }

  formatDurationToString(seconds) {
    if (isNaN(seconds)) return '00:00'

    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  // Cleanup methods
  cleanupResources() {
    this.cleanupHls()

    // Reset buffer bars
    this.updateTargets(this.bufferBarTargets, (bar) => {
      bar.value = 0
    })

    // Clear timeouts
    if (this.controlsTimeoutId) {
      clearTimeout(this.controlsTimeoutId)
    }
  }

  cleanupHls() {
    if (!this.hls) return

    this.hls.off(Events.MANIFEST_LOADED)
    this.hls.off(Events.FRAG_BUFFERED)
    this.hls.off(Events.ERROR)
    this.hls.destroy()
    this.hls = null
  }

  // UI spinner
  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.spinnerTarget.classList.add('flex')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('flex')
  }
}
