import { Controller } from "@hotwired/stimulus";

export default class AbstractPlayerController extends Controller {
  static targets = [
    "progressbar",
    "progressbarseek",
    "controllerprogressstring",
  ];

  getTimeInHoursMinutesAndSeconds(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return {
      seconds,
      minutes,
      hours,
    };
  }

  // Will return "5:32 min" or "1 h 5 min"
  getTimeInMoreHumanFriendlyFormat(parts) {
    if (parts.hours > 0) {
      return `${parts.hours} h ${parts.minutes} min`;
    }
    const paddedSeconds = String(parts.seconds).padStart(2, "0");
    return `${parts.minutes}:${paddedSeconds} min`;
  }

  formatTime(timeInSeconds) {
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    return {
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  }

  // Get the x position where the target starts
  getOffset(initialElement) {
    let offsetTotal = 0;
    let elem = initialElement;

    while (elem) {
      offsetTotal += elem.offsetLeft;
      elem = elem.offsetParent;
    }
    return offsetTotal;
  }

  formatDurationToString(time) {
    if (!Number.isFinite(time)) {
      return "00:00";
    }

    const durationParts = this.getTimeInHoursMinutesAndSeconds(time);
    const hoursStr = String(durationParts.hours).padStart(2, "0");
    const minutesStr = String(durationParts.minutes).padStart(2, "0");
    const secondsStr = String(durationParts.seconds).padStart(2, "0");

    // 00:00:00 or 00:00
    return durationParts.hours > 0
      ? `${hoursStr}:${minutesStr}:${secondsStr}`
      : `${minutesStr}:${secondsStr}`;
  }

  setMaxDuration(bar, seek, textIndicator, duration) {
    if (!bar || !seek || !textIndicator || !duration) return;

    bar.setAttribute("max", String(duration));
    seek.setAttribute("max", String(duration));

    textIndicator.textContent = this.formatDurationToString(duration);

    // If needed, uncomment and fix:
    // const durationParts = this.getTimeInHoursMinutesAndSeconds(duration);
    // const durationMoreHumanFriendly = this.getTimeInMoreHumanFriendlyFormat(durationParts);
    // this.initialplaydurationinfoTarget.textContent = durationMoreHumanFriendly;
  }

  updateProgressBarAndTimeDisplays(time) {
    const currentTime = Math.floor(time || 0);

    if (this.hasProgressbarTarget) {
      this.progressbarTarget.value = String(currentTime);
    }
    if (this.hasProgressbarseekTarget) {
      this.progressbarseekTarget.value = String(currentTime);
    }
    if (this.hasControllerprogressstringTarget) {
      this.controllerprogressstringTarget.textContent =
        this.formatDurationToString(currentTime);
    }
  }

  clampNumber(input, min, max) {
    return Math.min(Math.max(input, min), max);
  }

  moveBarSeeker(
    event,
    progressbarseekTarget,
    progressbarseektooltipTarget,
    playerTarget,
  ) {
    if (
      !(event.target instanceof HTMLElement) ||
      !progressbarseekTarget ||
      !progressbarseektooltipTarget ||
      !playerTarget
    ) {
      return;
    }

    const maxAttr = event.target.getAttribute("max");
    if (!maxAttr) return;

    // Clamp between 0 and media tag duration
    const skipTo = this.clampNumber(
      Math.round(
        (event.offsetX / event.target.clientWidth) * parseInt(maxAttr, 10),
      ),
      0,
      playerTarget.duration,
    );

    progressbarseekTarget.setAttribute("data-seek", String(skipTo));
    const t = this.formatTime(skipTo);
    progressbarseektooltipTarget.textContent = `${t.minutes}:${t.seconds}`;

    // Position tooltip without going off edges
    const offset = this.getOffset(progressbarseekTarget);
    const halfSize = progressbarseektooltipTarget.clientWidth / 2;
    let leftPixels = event.pageX - offset - halfSize;

    if (leftPixels + halfSize < 0) {
      leftPixels = -halfSize;
    }
    if (leftPixels + halfSize > event.target.clientWidth) {
      leftPixels = event.target.clientWidth - halfSize;
    }

    progressbarseektooltipTarget.style.left = `${leftPixels}px`;
  }
}
